/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f58adec8-014f-451f-af82-4f8b9edf23b3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_D4cOS22tY",
    "aws_user_pools_web_client_id": "5687mvtb9s6ds6n13ii6fo0nn0",
    "oauth": {}
};


export default awsmobile;
